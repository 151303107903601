.work {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  &-title {
    font-weight: 300;
    margin: 3rem auto;
    border-bottom: 3px solid $border-bottom-color;
    width: fit-content;
    height: 2rem;
  }
  &-item {
    width: 20rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-company {
      font-size: $font-size-small;
      font-weight: 400;
    }
    &-title {
      font-size: $font-size-small;
      font-weight: 400;
    }
    &-years {
      font-size: $font-size-small;
      font-weight: 300;
      margin-bottom: 1rem;
    }
    &-description {
      font-size: $font-size-small;
      font-weight: 300;
      margin-bottom: 1rem;
      line-height: 30px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .work {
    flex-direction: row;
    align-items: flex-start;

    // height: 45rem;
    &-list {
      margin: 3rem 0 0 10rem;
    }
    &-item {
      width: 658px;
      align-items: flex-start;
      &-company {
        font-size: $font-size-title;
      }
      &-title {
        font-size: $font-size;
      }
      &-years {
        font-size: $font-size;
      }
      &-description {
        font-size: $font-size;
      }
    }
  }
}
