@import "./variables.scss";

.skills {
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 3rem 0;
  &-title {
    font-weight: 300;
    margin: 3rem auto;
    border-bottom: 3px solid $border-bottom-color;
    width: fit-content;
    height: 2rem;
  }
  &-list {
    width: 100%;
  }
  &-item {
    margin-bottom: 1rem;
    &-title {
      margin-bottom: 0.5rem;
    }
  }
}

@media screen and (min-width: 1024px) {
  .skills {
    flex-direction: row;
    align-items: flex-start;

    &-list {
      margin: 3rem 0 0 10rem;
      width: 658px;
    }
    &-item {
      align-items: flex-start;
    }
  }
}
