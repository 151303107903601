//Button Colours
$btn-color: #444444;
$btn-nav-color: #525252;
$btn-drk-color: #0d0d0d;
$btn-grey: #34495e;

//Background Colors
$background-blue: #000524;
$backgroud-white: #ffffff;
$background-grey: #ebeeee;
$background-drkgrey: #191919;
$background-hero:#34495E;
$background-form: #373233;

//Text Colours
$title-color-onDark: #ffffff;
$title-color-ondarkgrey: #ebeeee;
$title-color-onLight: #313131;
$title-color-onLgtGrey: #95a3a3;
$text-color-onDark: #cccccc;
$text-color-onLight: #838c95;
$text-color-ondarkgrey: #636363;
$text-color-lightgrey: #dddddd;

//Border Colors
$border-bottom-color: #0762f9;
$border-seperator: #e8e8e8;

//Skills Colors
$color1: #313131;
$color2: #333333;
$color3:#6e7881;
$color4: #838c95;
$color5:#0762f9;
$color6: #a46820;
$color7:#a511a4;
$color8:#b79f0f;
$color9:#e5ee8d;
$color10:#d75847;
$color11:#a3a98d;

//Font Sizes
$font-size-header: 2.5rem;
$font-size: 1rem;
$font-size-small: .875rem;
$font-size-title: 1.6rem;





