.works {
  height: 75rem;
  background-color: $background-grey;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  &-title {
    font-weight: 300;
    margin: 3rem 0;
    font-size: $font-size;
    color: $title-color-onLgtGrey;
    width: fit-content;
    margin: 2rem auto;
  }
  &-item {
    width: 20.5rem;
    height: 12.5rem;
    background-color: white;
    margin-bottom: 1.5rem;
    &-img {
      width: 100%;
      height: 10.4413rem;
      object-fit: cover;
    }
    &-title {
      font-size: $font-size;
      font-weight: 300;
      color: $title-color-onLgtGrey;
      display: flex;
      justify-content: center;
    }
  }
}

@media screen and (min-width: 1024px) {
  .works {
    height: 40rem;
    &-title {
      // margin-left: 25rem;
      display: flex;
      justify-content: center;
    }
    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      flex-direction: row;
    }
    &-item {
      width: 220px;
      height: 155px;
      margin: 1rem;
      &-img {
        height: 8rem;
      }
    }
  }
}
